<template lang="pug">
  v-card.video-watcher.pa-4
    v-card-title
      | Thumbnail Video
      v-spacer
        v-icon(aria-label="Close" @click="$emit('close')")
          | mdi-close
    v-card-text.px-0
        v-row.text-center
            v-col(cols="12")
                v-img(:src="thumbnail" max-width="300")
    v-card-actions.text-center
        v-spacer
            v-btn(color='primary' @click="$emit('close')") Cancel
            v-btn(color='secondary' :loading="loading" @click="save") Save thumbnail
</template>

<script>
  export default {
    props: {
      thumbnail: String,
    },

    data () {
      return {
        loading: false,
      }
    },

    beforeDestroy () {
      this.loading = false
    },

    methods: {
      save () {
        this.loading = true
        this.$emit('save')
      },
    },

  }
</script>
